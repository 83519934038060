
jQuery.fn.preventDoubleSubmission = function() {
  $(this).on('submit',function(e){
    var $form = $(this);

    if ($form.data('submitted') === true) {
      e.preventDefault();
    } else {
      $form.data('submitted', true);
    }
  });

  return this;
};

class Common {
  static initClass() {
    this.prototype.$container = null;
  }

  constructor($container) {
    this.$container = $container;

    this.initBasic();
    this.initAjaxPrefilter();
  }

  initBasic() {
    const self = this;

    $('form').preventDoubleSubmission();

    $('.dropdown-toggle').click(function () {
      const $elem = $(this)

      $elem.next(".dropdown-menu").toggleClass("d-block");
    })

    $('body').delegate('.js-toggle-hide', 'click', function(e) {
      e.preventDefault()
      const type = $(this).data('type')

      $(`.js-toggle-target[data-type="${type}"]`).toggleClass('d-none')
    })

    $('body').delegate('.js-checkbox-d-toggle', 'change', function(e) {
      e.preventDefault()
      const target = $(this).data('target')

      $(`.js-target-d-toggle[name="${target}"]`).toggleClass('d-none')
    })

    $('body').delegate('.js-submit-on-change', 'change', function(e) {
      e.preventDefault();
      $(this).parents('form').submit()
    });

    $('body').delegate('.js-post-item', 'click', function(e) {
      e.preventDefault();

      if (confirm(`Are you sure you want to ${$(this).text()}?`)) {
        self.postItem($(this))
      }
    });

    $('body').delegate('.js-put-item', 'click', function(e) {
      e.preventDefault();

      if (confirm(`Are you sure you want to ${$(this).text()}?`)) {
        self.putItem($(this))
      }
    });

    $('body').delegate('.js-delete-item', 'click', function(e) {
      e.preventDefault();

      if (confirm("Are you sure?")) {
        self.deleteItem($(this))
      }
    });

    $(".multi-select").select2({
      theme: "bootstrap-5",
    });

    $(".sku-suggestions").select2({
      theme: "bootstrap-5",
      allowClear: true,
      placeholder: "Search SKUs",
      ajax: {
        url: "/autocomplete",
        dataType: "json",
        width: 'style',
        delay: 250,
        data: function(params) {
          return {
            q: params.term,
            page: params.page,
            per_page: 10
          };
        },
        processResults: function(data, page) {
          return {
            // Select2 requires an id, so we need to map the results and add an ID
            // You could instead include an id in the tsv you add to soulheart ;)
            results: data.matches.map(function(item) {
              const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',

                // These options are needed to round to whole numbers if that's what you want.
                //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
              });

              return {
                id: item.text,
                text: `${item.text} - ${item.title ? item.title.substr(0, 24) : ``} - ${formatter.format(parseFloat(item.price))}`
              };
            }),
            pagination: {
              // If there are 10 matches, there's at least another page
              more: data.matches.length === 10
            }
          };
        },
        cache: true
      }
    }).on('select2:select', function (e) {
      const selectItem = e.params.data;
      const $elem = $(e.target);
      if (selectItem && selectItem.text) {
        if ($elem.hasClass("auto-submit")) {
          $elem.val(selectItem.id)
          $elem.parents('form').submit();
        }
      }
    });
  }

  locationHref(exclude) {
    const { origin, pathname, search, hash } = window.location
    !Array.isArray(exclude) && (exclude = [exclude])
    const newSearch = new URLSearchParams(search)
    exclude.forEach((key) => newSearch.delete(key))
    return `${origin}${pathname}${newSearch.toString() !== '' ? `?${newSearch.toString()}` : ''}${hash}`
  }

  ajaxItem($element, type = 'POST') {
    const label = $element.text()
    $element.css('pointer-events', 'none').text('Processing...')
    $.ajax({
      type: type,
      url: $element.data('href')
    }).done((response) => {
      if (response && response.redirect === this.locationHref('page')) {
        window.location.reload()
        return
      }

      window.location = (response && response.redirect) || '/'
    }).fail((jqXHR, _textStatus) => {
      $element.css('pointer-events', '').text(label)
      if (jqXHR.status >= 400 && jqXHR.status < 500 && jqXHR.responseJSON) {
        this.createFlash(jqXHR.responseJSON.error || 'Something went wrong')
      }
    });
  }

  postItem($element) {
    this.ajaxItem($element, 'POST')
  }

  putItem($element) {
    this.ajaxItem($element, 'PUT')
  }

  deleteItem($element) {
    this.ajaxItem($element, 'DELETE')
  }

  initAjaxPrefilter() {
    $.ajaxPrefilter((options, originalOptions, xhr) => {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    })
  }

  createFlash(message) {
    $('main').prepend(
      `<div class="alert alert-warning alert-dismissible fade show mb-5 position-relative" role="alert" style="z-index: 0">
          ${message}
          <button aria-label="Close" class="btn-close" data-bs-dismiss="alert" type="button"></button>
      </div>`
    )
  }
}

Common.initClass();
const root = window;

if (!root.App.NameSpaces.Common) {
  root.App.NameSpaces.Common = Common;
}

$(document).ready(() => {
  App.Instances.common = new Common($('body'));
});
